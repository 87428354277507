<template>
    <CRow>
        <CCol col="12" xl="12">
            <CCard>
                <CCardHeader>
                    <CCol col="6" style="font-size: 25px;">تعديل المنطقة</CCol>
                </CCardHeader>
                <CCardBody>
                            <div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <form @submit.prevent="UpdateArea">
                                            <div class="form-group">
                                                <label style="padding:5px;">الاسم</label>
                                                <input type="text" class="form-control" v-model="area.name" required>
                                            </div>
                                            <div class="form-group">
                                                <label for="cities">
                                                    المدينة
                                                </label>
                                                <multiselect v-model="selected" :value="selected"  id="cities" :options="cities" :searchable="true" selectLabel="اضغط لإختيار هذا العنصر" :close-on-select="true"
                                                    openDirection = "bottom" selectedLabel=" العنصر المحدد" deselectLabel="اضغط لإلغاء اختيار هذا العنصر"
                                                    class=" required"  placeholder="اختر..." label="name" track-by="name" required> <span slot="noOptions">اللائحة فارغة</span> <span slot="noResult">لا توجد نتيجة</span>
                                                </multiselect>
                                                <!-- <select v-model="selected" id="cities" class="form-control" required>
                                                    <option v-for="option in cities" v-bind:value="option.id">
                                                        {{ option.name }}
                                                    </option>
                                                </select> -->
                                            </div>
                                            <button type="submit" class="btn btn-success" >تحديث البيانات</button>
                                            &emsp;
                                            <CButton color="warning" @click="goBack">رجوع</CButton>
                                        </form>
                                    </div>
                                </div>
                            </div>
                </CCardBody>
            </CCard>
        </CCol>

    </CRow>
</template>

<script>
    import swal from 'sweetalert'
    import $ from 'jquery'
    export default {
        name : 'EditArea',
        data() {
            return {
                area: [],
                image:'',
                cities:[],
                selected: '',
            }
        },
        created() {
            console.log(this.$route.params.id);
            this.$http
                .get(`${process.env.VUE_APP_URL}areas/${this.$route.params.id}/edit`)
                .then((response) => {
                    this.area = response.data.data.area;
                    this.selected = response.data.data.area.city_id;
                    this.selected = this.area.city;
                });
                let cities = [];
                this.$http
                    .get(`${process.env.VUE_APP_URL}cities-list`)
                    .then((response) => {
                        $.each(response.data.data, function(key, value) {
                            cities.push({id:value.id,name:value.name})
                        });
                        this.cities = cities;
                    });
        },
        methods: {
            UpdateArea() {
                let formData = new FormData();
                formData.append('name', this.area.name);
                formData.append('_method', 'PUT');
                if (this.selected.id)
                    formData.append('city_id', this.selected.id);
                this.$http
                    .post(`${process.env.VUE_APP_URL}areas/${this.$route.params.id}`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                    .then((response) => {
                        if(!response.data.error)
                        {
                            swal({ title:response.data.message, buttons: "تم"});
                        }
                        else
                        {
                            swal({ title:response.data.message, buttons: "تم"});
                        }
                    });
            },
            goBack() {
                this.areasOpened ? this.$router.go(-1) : this.$router.push({path: '/areas'})
            },
        }
    }

</script>
